<template>
  <main>
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.machineryInYard')}}  </b>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12" class="d-flex justify-content-end py-2">
            <CButton
              square
              color="add"
              class="d-flex align-items-center"
              @click="toggleCreate"
              v-c-tooltip="{
                content: $t('label.machineryInYard'),
                placement: 'top-end'
              }"
            >
              <CIcon name="cil-playlist-add"/>&nbsp;
              {{$t('label.nueva')}}
            </CButton>
          </CCol>
        </CRow>
        <dataTableExtended
          class="align-center-row-datatable"
          :items="computedMachineryYardList"
          :fields="fields"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          :loading="loading"
          sorter
        >
          <template #loading>  
            <loading/>
          </template>  
          <template #Status="{ item }">
            <td class="cell-center text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #options="{ item }">
            <td>
 
                <CButton
                  size="sm"
                  square
                  color="edit"
                  class="d-flex align-items-center"
                  @click="toggleEdit(item)"
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.damageType'),
                    placement: 'top-end'
                  }"
                >
                  <CIcon name="pencil" />
                </CButton>
            
            </td>
          </template>
        </dataTableExtended>
      </CCardBody>
    </CCard>
    <modal-machinery-yard
      :modal.sync="modalActive"
      :branch="branch"
      :isEdit="isEdit"
      :MachineYardId="MachineYardId"
      @Update-list="Update"
    />
  </main>
</template>
<script>
import { mapState } from 'vuex';
import ModalMachineryYard from './modal-machinery-yard';
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';


function data() {
  return {
    items: [],
    modalActive: false,
    isEdit: false,
    MachineYardId: '',
    loading: false,
  };
}

function fields(){
  return [
     { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:3%; text-align:center', filter: false},
     { label: this.$t('label.yard'), key: 'YardName',_style: 'width: 14%;',},
     { label: this.$t('label.machinery'), key: 'MachineName',_style: 'width: 14%;',},
     { label: this.$t('label.type'), key: 'TpMachineName',_style: 'width: 14%;',},
     { label: this.$t('label.brand'), key: 'BrandName',_style: 'width: 14%;',},
     { label: this.$t('label.model'), key: 'ModelName',_style: 'width: 14%;',},
     { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-uppercase', _style:'width: 8%' },
     { label: this.$t('label.date'), key: 'FormatedDate',_classes:'text-center', _style:'width: 8%' },
     { label: this.$t('label.status'), key: 'Status', _classes: 'text-center text-uppercase', _style:'width: 4%' },
     { label: '', key: 'options', _style: 'width:3%; min-width:45px;', sorter: false, filter: false },
  ];
}

function toggleEdit(item) {
  this.MachineYardId = item.MachineYardId;
  this.isEdit = !this.isEdit;
  this.modalActive = true;
}

function computedMachineryYardList() {
  return this.items.map((item) => {
    return {
      ...item,
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
    };
  });
}
function Update() {
  this.modalActive = false;
  this.isEdit = false;
  this.MountedMachineryYard();
}

function MountedMachineryYard() {
  this.loading = true;
  this.$http
    .get('MachineYard-list?YardId=', {CompanyBranchId: this.branch.CompanyBranchId})
    .then((response) => (this.items = response.data.data))
    .finally(() => {
      this.loading = false;
    });
}

function toggleCreate() {
  this.modalActive = !this.modalActive;
}


export default {
  name: 'machineryInYard-index',
  components: { ModalMachineryYard },
  data,
  mixins: [GeneralMixin],
  methods: {
    Update,
    MountedMachineryYard,
    toggleCreate,
    toggleEdit,
  },
  computed: {
    computedMachineryYardList,
    fields,
    ...mapState({
      branch: state => state.auth.branch,
    }),
  },
  mounted: MountedMachineryYard,
};
</script>